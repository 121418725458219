/* Helpers
  =============== */

.breakout {
    position: relative;
    width: auto;
    margin-left: calc(-50vw + 50%);
    display: block;
    margin-right: calc(-50vw + 50%);
}

.full-width {
    display: block;
    width: 100%;
    height: auto;
}

ul.no-indent {
    padding-left: 0;
    list-style: none;
}

a.inverted {
    text-decoration: none;
    &:hover,&:active {
        text-decoration: underline;
    }
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    &.sticky-top { top: 0; }
    &.sticky-bottom { bottom: 0; }
}

// Classic clearfix
.clearfix::before,
.clearfix::after {
    content: " ";
    display: block;
}

.clearfix:after {
    clear: both;
}

// Text alignment
.text-left {
    text-align: left;
    text-align-last: left;
}

.text-right {
    text-align: right;
    text-align-last: right;
}

.text-center {
    &, > * {
        text-align: center;
        text-align-last: center;
    }
}

.text-justify {
    text-align: justify;
}

.no-wrap {
    white-space: nowrap;
}

// Display
.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

// Vertical center
.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex, .flex > * {
    display: flex;
}

.static {
    position: static !important;
}

// Responsive images
.responsive-image {
    max-width: 100%;
    height: auto;
}

.bg-image {
    background-size: cover;
    background-repeat: no-repeat;
}

// Display State
.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.invisible {
    visibility: hidden;
}

.hidden-xs {
    @include extra-small {
        display: none !important;
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently  */
}

// Floats
.float-left {
    float: left;
}

.float-right {
    float: right;
}

// Padding
.no-padding-top {
    padding-top: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.padding-top {
    padding-top: 2rem;
}

.padding-bottom {
    padding-bottom: 2rem;
}

// Margins
.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.margin-top {
    margin-top: $margins*2;
}

.margin-bottom {
    margin-bottom: $margins*2;
}

// Backgrounds
.alternate-background {
    background: $alternate-background;
    color: $alternate-color;
}

// Screen reader text for accessibility
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.small-font {
    font-size: $small-font-size;
}

.tiny-font {
    font-size: $tiny-font-size;
}

.smaller-font {
    font-size: $smaller-font-size;
}

.bigger-font {
    font-size: $bigger-font-size;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
