section#footer {
    @include small {
        background-color: black;
        margin-bottom: 0;

        footer {
            padding: 2em 0em !important;

            h4 {
                margin-bottom: 1em;
            }
        }
    }
}

footer {
    background-image: url("../../images/Footer/background.svg");
    color: #ffffff;
    padding: 80px;
    padding-top: 120px;
    padding-bottom: 32px;

    @include extra-small {
        width: calc(100% + 60px);
        text-align: center;

        p {
            width: 100%;
        }
    }

    @include small {
        text-align: center;

        p {
            width: 100%;
        }
    }

    h2,
    h3,
    h4,
    h5 {
        color: #ffffff;
    }

    h4 {
        margin-bottom: 3em;
    }

    p {
        font-weight: lighter;
        font-size: 1.6rem;
    }

    a {
        text-decoration: none;
        color: #ffffff;
    }

    .footer-content {
        .grid {
            margin-bottom: 25px;
        }
    }

    ul.contact-icons {
        padding-left: 0;

        li {
            list-style: none;
            display: inline;
            margin-right: 10px;
        }
    }

    .footer-bottom {
        > div {
            display: flex;
            align-items: flex-end;
            align-content: flex-end;

            .bottom-txt {
                font-weight: bolder;
                font-size: 3rem;
            }

            .copyright-txt {
                font-weight: lighter;
                font-size: 1.2rem;
            }
        }
    }
}
