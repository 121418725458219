/* Mixins
  =============== */

// Media query for mobile layout
@mixin extra-small {
    @media (max-width: #{$xs - 1}) {
        @content;
    }
}

@mixin small-down {
    @media (max-width: #{$sm - 1}) {
        @content;
    }
}

@mixin small {
    @media (min-width: #{$xs}) and (max-width: #{$sm - 1}) {
        @content;
    }
}

// tablet
@mixin medium {
    @media (min-width: #{$sm}) and (max-width: #{$md - 1}) {
        @content;
    }
}

@mixin medium-down {
    @media (max-width: #{$md - 1}) {
        @content;
    }
}


@mixin medium-up {
    @media (min-width: #{$md - 1}) {
        @content;
    }
}

// desktop
@mixin large {
    @media (min-width: #{$md}) and (max-width: #{$lg - 1}) {
        @content;
    }
}

@mixin large-up {
    @media (min-width: #{$md}) {
        @content;
    }
}


// wide screen
@mixin xlarge {
    @media (min-width: #{$lg}) {
        @content;
    }
}