.card {
    position: relative;
    overflow: hidden;
}

.case-card {
    border-radius: 40px;
    height: 470px;
    box-shadow: 0 .5rem 1.5rem 0 rgba(0,0,0,.07),0 1.5rem 1.5rem 0 rgba(50,50,93,.1);

    &:hover {
        transition: .4s;
        transform: scale(1.05);
        box-shadow: 0 10px 10px rgba(0,0,0,.15) !important;
        cursor: pointer;
    }

    .overlay-grad {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 83.58%);
    }

    img {
        z-index: 0;
    }

    .overlay {
        z-index: 2;
        opacity: 0.8;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        z-index: 5;
        color: #ffffff;
        height: 100%;
        padding: 30px;

        h4 {
            font-weight: 700;
            color: #ffffff;
            text-align: center;
            font-size: 36px;
            word-spacing: 9999px; // Forces word break
        }

        p {
            font-weight: 200;
            font-size: 18px;
            color: #ffffff;
        }
    }
}
