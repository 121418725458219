/* + 0px makes sure the value is cast as a number, so calculations can be done with it */
$xs: map_get($breakpoints, xs) + 0px;
$sm: map_get($breakpoints, sm) + 0px;
$md: map_get($breakpoints, md) + 0px;
$lg: map_get($breakpoints, lg) + 0px;
$xlg: map_get($breakpoints, xlg) + 0px;

$reflex-xs: $xs !default;
$reflex-sm: $sm !default;
$reflex-md: $md !default;
$reflex-lg: 1060px !default;
$reflex-xlg: 1140px !default;

$fullwidth-large-nav-min: 1120px;
$fullwidth-xlarge-nav-min: 1520px;

/* Colors */

$light-blue: #f2fbfd;

$background: #fff; /* body bg */
$primary-color: #2b85d9; /* active menu, link color */
$secondary-color: #fff; /* header color, nav/button bg */
$secondary-color-active: #73dffc;
$secondary-background: #5955ac;
$accent-color: #ec1c74; /* search button, next/right button */
$cta-color: #00b988; /* cta button bg */

/* icon: 33A8ED; */
$alternate-background: $light-blue; /* alternate block bg */
$alternate-color: #5a5968; /* alternate block font color */
$link-color: #176301d6;
$link-hover-color: $link-color;
$highlight: $accent-color;
$dark-color: #212b36;
$error: $accent-color;

/* tags */

$darkblue: #2571ce; /* MLPA */
$orangeyellow: #fd9c19; /* digitalMLPA */
$turquoisegreen: #00e0ad; /* Melt */
$purple: #9426b0; /* Coffalyser (of #70289F) */

/* Typography */

// Body font
$font-size: 62.5%;
$body-font-size: 1.8rem;
$smaller-font-size: 0.9em; // relative to current font size
$bigger-font-size: 1.15em; // relative to current font size
$small-font-size: $body-font-size * 0.8;
$tiny-font-size: $body-font-size * 0.7;
$font-style: normal;
$font-variant: normal;
$font-weight: 400;
$font-color: #000000;
$font-color-alternate: #5a5968;
$font-family: "RNSSanz", -apple-system, BlinkMacSystemFont, Helvetica Neue,
Helvetica, Arial, sans-serif;
$line-height: 1.6;
$condensed-line-height: 1.4;

$text-shadow: 0 3px 19px rgba(0, 0, 0, 0.08);

// Headings
$heading-font-color: #000000;
$heading-font-weight: 700;
$heading-font-family: $font-family;
$heading-line-height: 1.4;

// Heading font size
$h1: calc(1.5vw + 2.5rem);
$h2: 3.2rem;
$h3: 2.6rem;
$h4: 2rem;
$h5: 1.8rem;

$h1-fixed: calc(5rem);

// Mobile font size
$h1-mobile: 3.2rem;

$semibold: 500;
$bold: 600;

/* Padding */

$padding: calc(1em + 0.5vw);
$margins: 0.8em;
$content-padding: 0 0 3em 0;
$content-padding-mobile: 0 0 2em 0;

/* Borders */

$border-width: 1px;
$border-style: solid;
$border-color: #e3e3e3;
$border-radius: 0.4rem;
$borders: $border-width $border-style $border-color;

$box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.07),
0 1.5rem 1.5rem 0 rgba(50, 50, 93, 0.1);
$box-shadow-heavy: 0 1.5rem 3.5rem 0 rgba(50, 50, 93, 0.1),
0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.07);

$overlay-gradient: linear-gradient(-180deg, rgba(0, 0, 0, 0) 32%, #000000 95%);

/* Forms */

$forms: (
    "[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea"
);
$buttons: (
    ".button, a.button, button, [type=submit], [type=reset], [type=button]"
);
$input-background: $background;
$placeholder: darken($accent-color, 20%);
$form-border: 1px solid $border-color;
$form-border-hover: 1px solid darken($border-color, 10%);
$form-border-focus: 1px solid $link-color;
$forms-font-size: 1.8rem;

/* Buttons */

$button-background: $cta-color;
$button-background-hover: darken($button-background, 10%);
$button-color: #ffffff;
$button-font-weight: 500;
$button-font-family: $font-family;
$button-font-size: $forms-font-size;
$button-border-width: 1px;
$button-border-style: solid;
$button-border-color: $button-background;
$button-border-radius: $border-radius;
$button-text-transform: none;

// Accent buttons
$accent-button-background: $accent-color;
$accent-button-color: #ffffff;
$accent-button-color-hover: #ffffff;

// Alternative buttons
$alternative-button-background: $secondary-background;
$alternative-button-color: #ffffff;
$alternative-button-color-hover: #ffffff;

// Muted Buttons
$muted-border: 1px solid $accent-color;
$muted-border-hover: 1px solid darken($accent-color, 30%);
$muted-background: transparent;
$muted-background-hover: transparent;
$muted-color: darken($accent-color, 50%);
$muted-color-hover: darken($accent-color, 50%);

/* Tables */

$stripes: #f8f8f8;
$caption: #ababab;

/* Code */

$code-color: $font-color;
$code-size: 14px;
$code-family: Menlo, monospace;
$code-background: transparent;
$code-borders: $borders;
