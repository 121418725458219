.hero.grid {
    p {
        margin-bottom: 30px;
    }
}


#banner {
    > .container {
        position: relative;

        @include small-down {
            > img {
                max-width: calc(100% + 110px);
                left: -55px;
            }
        }

        > .grid {
            position: absolute;
            top: 35%;
            width: calc(100% - 60px);

            @include small-down {
                top: 0;
                height: 100% !important;
            }

            .text-content {
                @include small-down {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            > div {
                padding: 35px;
            }

            h2 {
                text-align: center;
                margin-bottom: 35px;
                color: #ffffff;
            }

            p {
                text-align: center;
                color: #ffffff;
                font-weight: 200;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}