input,
textarea {
    border: 1px solid #cad1d7;
    border-radius: 4px;
    font-weight: 300;
    padding: .65em;
    outline: none;
    background: #fff;
    margin-bottom: .1em;
    font-size: 1.8rem;
    width: 100%;
    max-width: 100%;
    overflow: visible;
}
form {
    .form-feedback {

        p.success {
            padding: 25px;
            color: #4f7904;
            border: 1px solid #8ad20a;
            border-radius: 15px;
            background-color: #a3ff000a;
        }

        p.failed {
            padding: 25px;
            color: #97680d;
            border: 1px solid #d28c0a;
            border-radius: 15px;
            background-color: #ff83000a;
        }
    }
}



