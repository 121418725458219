
$breakpoints: (xs: 576, sm: 768, md: 940, lg: 1280, xlg: 1920);
/* variables & fonts */
@import 'app/Resources/assets/fonts/fonts';
@import 'variables';
@import '~reflex-grid/scss/includes/_variables';
@import 'breakpoints';

/* prepare for ignition */
@import '~normalize.css/normalize';
@import 'base';
@import 'helpers';
@import 'init';

/* external libs */
@import '~reflex-grid/scss/includes/_mixins';
@import '~reflex-grid/scss/includes/_grid';
@import '~reflex-grid/scss/includes/_helpers';
@import '~swiper/swiper-bundle.css';

@import 'reset'; /* reset things if necessary (if wrong defaults set by external libs) */

/* core elements */
@import 'main';

/* reusable components */
@import 'Components/card';
@import 'Components/navigation';
@import 'Components/footer';
@import 'Components/buttons';
@import 'Components/forms';
@import 'Components/tables';

/* page specific */
@import 'Pages/home';
@import 'Pages/projectDetail';
