a {
    text-decoration: none;
}

.button {
    padding: 10px 15px;
    display: inline;
    border-radius: 15px;
    border: none;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    min-width: 150px;
    cursor: pointer;

    &.primary {
        background-color: #000000;
        color: #ffffff;
    }

    &.secondary {
        background-color: #176301;
        color: #ffffff;
    }

    &.terciary {
        background-color: #9C9C9C;
        color: #ffffff;
    }
}