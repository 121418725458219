
img {
    max-width: 100%;
}

.uppercase {
    text-transform: uppercase;
}

img.bg-img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.bg-img {
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-white {
    background-color: #ffffff;
}

.bg-grey {
    background-color: #F5F5F5;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;

    &:hover {
        opacity: 0.4;
    }
}

.client-inline {
    display: flex;
    align-items: center;

    img {
        margin-right: 10px;
        height: 30px;
        border-radius: 50%;
    }
}

section.simple-wysiwyg {
     ol {
         padding-left: 0;

         li {
             &::marker {
                 display: none;
             }

             margin-right: 10px;
             list-style: none;
             display: inline;
         }
    }
}

section.two-column-layout {
    p {
        width: 85%;
    }
}

.tooltags {
    display: flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > .tooltag {
        cursor: pointer;
        margin: 2em;
        margin-top: 0 !important;

        img.colored {
            display: none;
        }

        img {
            height: 65px;

            @include large {
                height: 55px;
            }

            @include medium {
                height: 55px;
            }

            @include small {
                height: 45px;
            }

            @include extra-small {
                height: 35px;
            }
        }

        @include small {
            margin: 1.5em;
        }

        @include extra-small {
            margin: 1em;
        }
    }
}

section#cases {
    margin-bottom: 5em;

    h2 {
        text-align: center;
    }

    .cases.grid {
        > div {
            margin-bottom: 50px;

            &:nth-child(3n+2) {
                @include medium-up {
                    & {
                        top: 45px;
                    }
                }
            }
        }

        [class*="col-"] {
            @include medium-up {
                & {
                    padding: 0 15px;
                }
            }
        }
    }
}

section#services {
    h2,
    h3 {
        text-align: center;
    }

    h3 {
        margin-bottom: 25px;
    }

    .service.card {
        position: relative;
        overflow: hidden;
        padding: 25px;
        padding-top: 40px;
        border: 1px solid black;
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 400px;
        margin-bottom: 3em;

        @include small {
            height: 330px;
        }

        &:hover {
            border-color: #176301;
        }

        img {
            height: 200px;
            margin-bottom: 25px;
        }
    }
}
