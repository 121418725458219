.sticky {
    z-index: 99;
    margin-bottom: 3em;
}

#nav-main {
    .nav-primary {
        display: flex;
        align-items: center;
        background-color: white;
        min-height: 100px;

        .logo {
            height: 50px;
        }

        > .container > .grid {
            margin-bottom: 0;
            justify-content: space-between;
        }

        .primary {
            justify-content: flex-end;
            display: flex;
            align-items: center;

            > ul.flex {
                position: relative;
                top: 5px;
            }

            ul.flex {
                > li {
                    margin-right: 40px;
                    height: 35px;

                    a {
                        color: #000000;
                        font-size: 14px;
                        font-weight: bold;
                        text-transform: uppercase;

                        &.active {
                            color: #176301;
                        }
                    }

                    &:hover {
                        border-bottom: 3px solid #176301;
                    }
                }
            }

            > .email-icon {
                background-image: url("../../images/Header/icon_mail.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                height: 14px;
                width: 60px;
                position: relative;
                top: -6px;

                &:hover {
                    cursor: pointer;
                    background-image: url("../../images/Header/icon_mail_black.svg");
                }

                @include medium-down {
                    display: none;
                }
            }

            > .hamburger-icon {
                cursor: pointer;
                height: 3em;
                width: 3em;
                z-index: 1;
                align-items: center;
                justify-content: center;

                @include medium-up {
                    display: none;
                }
            }

            .dropdown-menu {
                display: none;
                position: absolute;
                background-color: black;
                border-radius: 20px;
                top: 3em;
                padding: 2em 3em;
                animation: createBox .25s;
                box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.07), 0 1.5rem 1.5rem 0 rgba(50, 50, 93, 0.1);

                @keyframes createBox {
                    from {
                        transform: scale(0);
                    }
                    to {
                        transform: scale(1);
                    }
                }

                @include medium-up {
                    display: none !important;
                }

                ul.flex {
                    display: flex;
                    flex-direction: column;
                    padding-left: 0;

                    > li {
                        margin-bottom: .5em;

                        a {
                            color: #fff;

                            &.active {
                                color: #176301;
                            }
                        }
                    }
                }

                ul.contact-icons {
                    padding-left: 0;

                    > li {
                        display: inline;
                        margin-right: 1em;
                    }
                }

                @include medium-up {
                    display: none;
                }
            }

            .inline-menu {
                @include medium-down {
                    display: none;
                }
            }
        }
    }
}
