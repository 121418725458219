/** =================== RNSSanz =================== **/

/** RNSSanz Light **/
@font-face {
    font-family: "RNSSanz";
    font-style: normal;
    font-weight: 200;
    src: url("EOT/RNSSanz-Light.eot");
    src: url("EOT/RNSSanz-Light.eot?#iefix") format('embedded-opentype'),
         url("WOFF2/RNSSanz-Light.woff2") format("woff2"),
         url("WOFF/RNSSanz-Light.woff") format("woff");
}

/** RNSSanz Black **/
@font-face {
    font-family: "RNSSanz";
    font-style: normal;
    font-weight: 300;
    src: url("EOT/RNSSanz-Black.eot");
    src: url("EOT/RNSSanz-Black.eot?#iefix") format('embedded-opentype'),
         url("WOFF2/RNSSanz-Black.woff2") format("woff2"),
         url("WOFF/RNSSanz-Black.woff") format("woff");
}

/** RNSSanz Normal **/
@font-face {
    font-family: "RNSSanz";
    font-style: normal;
    font-weight: 300;
    src: url("EOT/RNSSanz-Normal.eot");
    src: url("EOT/RNSSanz-Normal.eot?#iefix") format('embedded-opentype'),
         url("WOFF2/RNSSanz-Normal.woff2") format("woff2"),
         url("WOFF/RNSSanz-Normal.woff") format("woff");
}

/** RNSSanz Medium **/
@font-face {
    font-family: "RNSSanz";
    font-style: normal;
    font-weight: 400;
    src: url("EOT/RNSSanz-Medium.eot");
    src: url("EOT/RNSSanz-Medium.eot?#iefix") format('embedded-opentype'),
         url("WOFF2/RNSSanz-Medium.woff2") format("woff2"),
         url("WOFF/RNSSanz-Medium.woff") format("woff");
}

/** RNSSanz SemiBold **/
@font-face {
    font-family: "RNSSanz";
    font-style: normal;
    font-weight: 500;
    src: url("EOT/RNSSanz-SemiBold.eot");
    src: url("EOT/RNSSanz-SemiBold.eot?#iefix") format('embedded-opentype'),
         url("WOFF2/RNSSanz-SemiBold.woff2") format("woff2"),
         url("WOFF/RNSSanz-SemiBold.woff") format("woff");
}

/** RNSSanz Bold **/
@font-face {
    font-family: "RNSSanz";
    font-style: normal;
    font-weight: 600;
    src: url("EOT/RNSSanz-Bold.eot");
    src: url("EOT/RNSSanz-Bold.eot?#iefix") format('embedded-opentype'),
         url("WOFF2/RNSSanz-Bold.woff2") format("woff2"),
         url("WOFF/RNSSanz-Bold.woff") format("woff");
}

/** RNSSanz ExtraBold **/
@font-face {
    font-family: "RNSSanz";
    font-style: normal;
    font-weight: 700;
    src: url("EOT/RNSSanz-ExtraBold.eot");
    src: url("EOT/RNSSanz-ExtraBold.eot?#iefix") format('embedded-opentype'),
         url("WOFF2/RNSSanz-ExtraBold.woff2") format("woff2"),
         url("WOFF/RNSSanz-ExtraBold.woff") format("woff");
}

