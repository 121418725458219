
#hero-carrousel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 650px;
    overflow: hidden;
    top: -3em;

    .carrousel-bg {
        position: absolute;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    @include extra-small {
        height: 400px;
    }

    @include small {
        height: 500px;
    }
}

.background-image {
    position: absolute;
}

.title-content {
    display: inline-block;
    background-color: #ffffff;

    p {
        margin-bottom: 0;
    }

    h1 {
        margin: 0 0 15px 0;
    }

    @include extra-small {
        padding: 1em 2.5em 1em 2.5em;
    }

    @include small {
        padding: 1.5em 4em 1.5em 4em;
    }

    @include medium {
        padding: 2em 7em 2em 5em;
    }

    @include large {
        padding: 2.5em 4em 2.5em 2em;
    }

    @include xlarge {
        padding: 2.5em 4em 2.5em 2em;
    }
}

section#project-meta {


    @include small {
        background-color: #ffffff;


    }

    @include medium {
        background-color: #F5F5F5;
    }

    @include medium-up {
        background-color: #F5F5F5;
    }

    .grey-columns {
        position: relative;

        .grey-rect {
            display: none;
            position: absolute;
            top: 0;
            background-color: #F5F5F5;
            width: 500px;
            height: 100%;
            border-radius: 40px;
        }

        .grey-left,
        .grey-right {
            background-color: #F5F5F5;
            padding: 4em;

            @include small-down {
                margin-bottom: 3em;

                .grey-rect {
                    display: initial;
                }
            }
        }

        .grey-right {
            border-radius: 40px 0 0 40px;
            position: relative;
            left: 1em;

            .grey-rect {
                left: 50%;
            }

            .desc-content {
                position: relative;
                left: -1em;
            }
        }

        .grey-left {
            border-radius: 0 40px 40px 0;
            position: relative;
            left: -1em;

            .grey-rect {
                left: -50%;
            }

            .meta-content {
                position: relative;
                left: 1em;

                @include medium-up {
                    & {
                        float: right;
                    }
                }

                > div {
                    margin-bottom: 1em;

                    h4 {
                        margin-bottom: 10px;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

#linear-gallery {
    .swiper-container {
        //overflow: initial;
    }

    .swiper-wrapper {
        padding-bottom: 50px;
        //overflow: initial;
    }

    .swiper-slide-active {
        .img-container {
            padding-right: 10px;
            padding-left: 0;
        }
    }

    .swiper-slide-next {
        .img-container {
            padding-left: 10px;
            padding-right: 0;
        }
    }

    .img-container {
        padding-left: 10px;
        padding-right: 0;

        img {
            border-radius: 20px;
            box-shadow: 0 .5rem 1.5rem 0 rgba(0,0,0,.07),0 1.5rem 1.5rem 0 rgba(50,50,93,.1);
        }

        &::after {
            position: absolute;
            content: "";
            width: 85px;
            height: 5px;
            bottom: -15px;
            left: calc(50% - 42.5px);
            background-color: rgba(255,255,255,0);
        }
    }

    .img-container {
        &:hover {
            &::after {
                background-color: #176301;
            }
        }
    }

    .swiper-button-next {
        right: -1em;
        height: 30px;
        width: 18px;

        background-image: url("../../images/arrow-right.svg");
        background-repeat: no-repeat;
    }

    .swiper-button-next::after {
        content: "";
    }

    .swiper-button-next:hover {
        //background-image: url("../../images/arrow-right-hover.svg");
    }

    .swiper-button-prev {
        left: -1em;
        height: 30px;
        width: 18px;

        background-image: url("../../images/arrow-left.svg");
        background-repeat: no-repeat;
    }

    .swiper-button-prev::after {
        content: "";
    }

    .swiper-button-prev:hover {
        //background-image: url("../../images/arrow-left-hover.svg");
    }
}


ul.tags {
    padding-left: 25px;

    li::before {
        display: inline;
        position: absolute;
        left: -0px;
        width: 12px;
        height: 12px;
        content: url("../../images/list-cross.svg");
    }
}